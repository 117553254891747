
import { computed, defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import Bus from "@/common/ts/Bus";
import store from "@/store";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String
  },
  components: {
    Dropdown1,
  },
  setup() {
    const toolbar = computed(() => {
      return store.getters.getToolbar;
    });

    function handleBack() {
      Bus.$emit("handleBack");
    }

    return {
      toolbarWidthFluid,
      toolbar,
      handleBack,
    };
  },
});

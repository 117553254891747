const DocMenuConfig = [
  {
    pages: [
      {
        heading: "首页",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "班级",
        route: "/class",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "课程",
        route: "/course",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "考试",
        route: "/exam",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
      // {
      //   heading: "练习",
      //   route: "/exercise",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      //   fontIcon: "bi-layers",
      // },
      // {
      //   heading: "新闻",
      //   route: "/news",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      //   fontIcon: "bi-layers",
      // },
    ],
  },
];

export default DocMenuConfig;

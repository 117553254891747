
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      footerWidthFluid,
      vue_app_beian: process.env.VUE_APP_BEIAN,
      current_year: new Date().getFullYear()
    };
  },
});
